import React, { useEffect } from "react";
import { useAuth } from "./authContext";
import { navigate } from "gatsby";

function PrivateRouteResolver({ children, location }) {
  const { isAuthenticated, checkAuthentication } = useAuth();
  const [mounted, setMounted] = React.useState(false);
  const [previousRoute, setPreviousRoute] = React.useState("/platform");

  const privateRoutes = React.useMemo(() => ["/platform"], []);

  const authRoute = "/auth";

  useEffect(() => {
    const checkAndNavigate = async () => {
      await checkAuthentication();

      if (location.pathname.includes(privateRoutes)) {
        if (!isAuthenticated) {
          setPreviousRoute(location.pathname + location.search);
          navigate(`/auth${location.search}`);
        }
      } else if (location.pathname.includes(authRoute)) {
        if (isAuthenticated) {
          navigate(previousRoute);
        }
      }
      setMounted(true);
    };

    if (mounted) {
      checkAndNavigate();
    } else {
      checkAuthentication()
        .then(() => {
          setMounted(true);
        })
        .catch(() => {
          setMounted(true);
        });
    }
  }, [
    location,
    isAuthenticated,
    mounted,
    checkAuthentication,
    previousRoute,
    privateRoutes,
  ]);

  return <>{children}</>;
}

export default PrivateRouteResolver;
